import { Input } from 'antd'
import React, { useState } from 'react'
import { http } from '../utils/network'
import config from '../utils/config'
import { fbq } from '../utils/facebook'

const ContactForm = () => {

	const [fullName, setFullName] = useState("")
	const [phone, setPhone] = useState("")
	const [address, setAddress] = useState("")
	const [loading, setLoading] = useState(false)

	const onSubmit = async () => {
		if (!fullName || !phone || !address || loading) return

		setLoading(true)

		const payload = {
			source_id: config.source_id,
			name: fullName,
			phone,
			quantity: config.offer_quantity,
			address
		}
	
		try {
			const response = await http(config.endpoint_url, "POST", payload)

			if (response.data.status == "success") {
				window.location.href = `${window.location.protocol}//${window.location.host}/thankyou`
				fbq('track', 'Purchase', {value: config.purchase_value, currency: 'EUR'})
			}
		} catch (e) {
			console.log(e)
		}

		setLoading(false)
	}

	return (
		<div className="flex flex-col items-center mt-8 p-4 bg-white rounded-xl border-dashed border-green-600 border-4">
			<span className='text-2xl font-bold mb-8'>Compila il modulo</span>
			<div className='flex flex-col items-start'>
				<span className='mb-2'>Nome e cognome</span>
				<Input className='w-80 text-base' placeholder="Inserisci nome e cognome" value={fullName} onChange={e => setFullName(e.target.value)} />
			</div>
			<div className='flex flex-col items-start mt-4'>
				<span className='mb-2'>Telefono (meglio cellulare)</span>
				<Input className='w-80 text-base' placeholder="Inserisci il tuo numero di telefono" value={phone} onChange={e => setPhone(e.target.value)} />
			</div>
			<div className='flex flex-col items-start mt-4'>
				<span className='mb-2'>Indirizzo</span>
				<Input className='w-80 text-base' placeholder="Es. Via Aldo Moro, 130" value={address} onChange={e => setAddress(e.target.value)} />
			</div>
			<div className='flex mt-8 items-center self-stretch'>
				<img className="h-20 w-20" src="../assets/checkout-preview.png" />
				<div className='flex flex-col items-center flex-1'>
					<span>Offerta attiva:</span>
					<span className='font-bold text-lg'>{config.product_name.toUpperCase()} {config.discounted_price}€</span>
				</div>
			</div>
			<div className='mt-8 w-80'>
				<span className='text-sm'>Cliccando "{config.cta_label.toUpperCase()}" confermi di aver preso visione dell'<a href={config.privacy_url} target='_blank' className='text-blue-700 cursor-pointer'>informativa sulla privacy</a>.</span>
			</div>

			<span className={`cta-base mt-6 ${loading ? "bg-green-300" : ""}`} onClick={onSubmit}>{config.cta_label.toUpperCase()}</span>
		</div>
	)
}

export default ContactForm
