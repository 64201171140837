export default {
	product_name: "Shedfield®",
	headline: "Dimentica la fatica con Shedfield®",
	subheadline: (
		<div className="mt-6 px-6 text-lg flex flex-col items-center">
			<span className="font-bold text-green-600">Ordina ora e paghi alla consegna. Nessun anticipo!</span>
			<span className="mt-4">Con questa nuova motosega elettrica avrai nella tua mano <span className="font-bold">potenza</span>, <span className="font-bold">leggerezza</span> e <span className="font-bold">lunga autonomia</span>.</span>
			<span className="mt-4 mx-4"><span className="font-bold">Shedfield®</span> taglia tronchi fino a 20 cm di diametro in maniera <span className="font-bold">precisa</span> e <span className="font-bold">veloce</span>.</span>
		</div>
	),
	bullet_points: [
		(
			<span className="ml-3 bullet-point-text">Leggera e semplicissa da usare</span>
		),
		(
			<span className="ml-3 bullet-point-text">Precisa, potente e veloce</span>
		),
		(
			<span className="ml-3 bullet-point-text">Batteria di lunga durata fino a 4 ore</span>
		),
		(
			<span className="ml-3 bullet-point-text">Impugnatura comoda ed egonomica</span>
		),
	],
	full_price: "149,99",
	discounted_price: "99,99",
	usages: [
		{
			image:  <video className="h-[300px] w-[300px] lg:w-[500px] lg:h-[500px]" autoPlay muted loop>
      					<source src={"../assets/usage-1.mp4"} type="video/mp4"/>
     					</video>,
			text: <div className="flex flex-col items-center">
				<span className="usage-text">Tagliare qualsiasi cosa</span>
				<span className="mt-2">Shedfield® può tagliare qualsiasi ramo o tronco, fino ad un diametro di 20 cm.</span>
				<span className="mt-2">Legna da ardere, potatura di alberi e molto altro</span>
			</div>
		},
		{
			image: <img src="../assets/usage-2.jpg" className="h-[300px] w-[300px] lg:w-[500px] lg:h-[500px] object-fit" />,
			text: <div className="flex flex-col items-center">
				<span className="usage-text">Lavorare per ore senza interruzioni</span>
				<span className="mt-2">Shedfield® è dotata di una batteria sorprendente da 6000 mAh ricaricabile.</span>
				<span className="mt-2">Può restare in attività per ben 4 ore di fila.</span>
			</div>
		},
		{
			image: <img src="../assets/usage-3.png" className="h-[300px] w-[300px] lg:w-[500px] lg:h-[500px] object-fit" />,
			text: <div className="flex flex-col items-center">
				<span className="usage-text">Lavorare in tutta comodità</span>
				<span className="mt-2">Shedfield® è realizzata con materiali resistenti e leggeri, pesa solo 800 grammi.</span>
				<span className="mt-2">Grazie al suo design ergonomico, non sentirai mai la mano affaticata e la presa sarà sempre ottimale.</span>
			</div>
		},
	],
	/* instructions: [
		{
			image: <img src="../assets/instructions-1.jpg" className="h-[300px] w-[300px] lg:w-[500px] lg:h-[500px]" />,
			text: <span className="usage-text">Istruzione 1</span>
		},
		{
			image: <img src="../assets/instructions-2.jpg" className="h-[300px] w-[300px] lg:w-[500px] lg:h-[500px]" />,
			text: <span className="usage-text">Istruzione 2</span>
		},
		{
			image: <img src="../assets/instructions-3.jpg" className="h-[300px] w-[300px] lg:w-[500px] lg:h-[500px]" />,
			text: <span className="usage-text">Istruzione 3</span>
		}
	], */
	reviews: [
		{
			avatar: "../assets/avatar-1.jpg",
			name: "Marco Russo",
			stars: 5,
			title: "Ottimo acquisto per il giardino",
			text: "Ho acquistato la Shedfield per il mio giardino e sono soddisfatto della sua performance. È leggera da maneggiare e ha dimostrato di essere utile per il taglio di rami e arbusti. È stato un buon acquisto per le mie esigenze di giardinaggio."
		},
		{
			avatar: "../assets/avatar-3.jpg",
			name: "Alessio De Luca",
			stars: 4,
			title: "Tagli precisi",
			text: "La Shedfield offre un buon livello di precisione nei tagli. È stata utile per lavori dettagliati e mi ha permesso di ottenere risultati soddisfacenti. La costruzione sembra solida e duratura."
		},
		{
			avatar: "../assets/avatar-2.jpg",
			name: "Marco Nwankwo",
			stars: 5,
			title: "Profumo Raffinato con un Tocco Speciale",
			text: "Shedfield è sorprendentemente potente considerando le sue dimensioni. È stata un'aggiunta utile al mio set di attrezzi da giardino, rendendo il taglio di rami e arbusti molto più semplice."
		}
	],
	store_name: "Giardinaggio Plus",
	privacy_url: "https://iframe.affiliatenetwork.link/landing/privacy.html",
	cta_label: "Ordina ora",
	endpoint_url: "https://network.worldfilia.net/manager/inventory/buy/bgood_shedfield.json?api_key=6a9c42be58b9b606237e83ef67b8b9dc",
	source_id: "30f7294e5c23",
	purchase_value: "18.00",
	offer_quantity: 1
}